















































































































































import { Component, Vue } from 'vue-property-decorator'
import { VehicleSecond } from '../../../functions/src/types/vehicle/second'
import Vehicle from '../../../functions/src/types/vehicle'
import moment from '@/plugins/moment'
import TripList from '@/components/admin/trip/list.vue'
import PartList from '@/components/admin/part/list.vue'
import DayList from '@/components/admin/day/list.vue'
import DeviceList from '@/components/admin/device/list.vue'
import VehicleInfo from 'functions/src/types/vehicle/info'
import firebase from '@/plugins/firebase'
import AuthStore from '@/store/AuthStore'

const currentTime = moment()
@Component({ components: { TripList, PartList, DayList, DeviceList } })
export default class AdminCard extends Vue {
  showSend = true
  start = {
    date: currentTime.format('YYYY-MM-DD'),
    time: currentTime.format('HH:mm:') + '00'
  }

  end = {
    date: currentTime.format('YYYY-MM-DD'),
    time: currentTime.format('HH:mm:') + '09'
  }

  trip = 0
  tripSecond = false

  info: VehicleInfo = {
    driverName: 'A',
    driverCode: '123456789011234567',
    plateNo: '12가3456',
    vin: '1234567890ABCDEFG',
    companyName: 'A',
    companyNo: '1234567',
    approvalNo: '1',
    serialNo: '1',
    modelNo: 'LDT',
    version: '1234',
    vehicleTypeIndex: 0,
    gSensorDirectionIndex: 0,
    canTypeIndex: 0,
    canSpeedIndex: 0,
    canModeIndex: 0,
    kFactor: 0,
    rpmFactor: 0,
    fuelFactor: 0,
    distanceFactor: 0,
    odoOffset: 0
  }

  deviceId = '+821099999999'

  interval = 10

  blanks: string[] = []

  loading = false

  get dataURL (): string {
    if (process.env.VUE_APP_FIREBASE_DATA === 'emulator') return 'http://localhost:7771/mobile-dtg/us-central1/api/'
    return 'https://us-central1-mobile-dtg.cloudfunctions.net/api/'
  }

  get startDateTime (): moment.Moment {
    return moment(`${this.start.date} ${this.start.time}`)
  }

  get endDateTime (): moment.Moment {
    return moment(`${this.end.date} ${this.end.time}`)
  }

  // get diffSeconds (): number {
  //   return this.endDateTime.diff(this.startDateTime, 'seconds')
  // }

  get vehicleSeconds (): VehicleSecond[] {
    if (this.interval < 1 || this.interval > 60 * 3) return []
    const seconds: VehicleSecond[] = []

    const val = { lat: 37, lng: 127, azi: 0, speed: 0 }
    for (let i = 0; i < this.interval; i++) {
      const currentTime = moment(this.startDateTime.toDate()).add(i, 'seconds')
      if (this.blanks.includes(currentTime.format('YYYY-MM-DD HH:mm:ss'))) continue
      const second: VehicleSecond = {
        time: currentTime.toISOString(),
        speed: val.speed * 10 % 150,
        speedFloat: 0,
        rpm: val.speed * 10,
        signal: 0,
        status: 0,
        latitude: val.lat,
        longitude: val.lng,
        azimuth: val.azi,
        gpsStatus: 0,
        gpsSpeed: 0,
        accX: 0,
        accY: 0,
        tripCount: 0,
        driverNo: 0,
        rssi: 0,
        trip: val.speed / 1000,
        dayTrip: 0,
        totalTrip: 0,
        fuel: 0,
        dayFuel: 0,
        totalFuel: 0,
        volt: 0,
        aebs: 0,
        ldws1: 0,
        temp1: 0,
        temp2: 0
      }
      val.speed++
      val.lat += 0.001
      val.lng += 0.001
      val.azi = (val.azi + 10) % 360
      seconds.push(second)
    }
    return seconds
  }

  get period (): string {
    if (this.vehicleSeconds.length < 1) return '기간 없음'
    const first = this.vehicleSeconds[0].time
    const last = this.vehicleSeconds.length < 2 ? first : this.vehicleSeconds[this.vehicleSeconds.length - 1].time
    return `${moment(first).format('YYYY-MM-DD HH:mm:ss')}\
     ~ ${moment(last).format('YYYY-MM-DD HH:mm:ss')}`
  }

  get blankTimes (): string [] {
    return this.vehicleSeconds.map(item => moment(item.time).format('YYYY-MM-DD HH:mm:ss'))
  }

  async sendData (seconds: VehicleSecond[]): Promise<void> {
    const vehicle: Vehicle = {
      deviceId: this.deviceId,
      info: Object.assign(this.info, { plateNo: '12가' + this.deviceId.slice(-4) }),
      seconds
    }
    // const url = 'http://localhost:5001/mobile-dtg/us-central1/api/vehicle'
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(vehicle)
    }

    const r = await fetch(this.dataURL + 'vehicle', options)
    console.log(r)
  }

  async send (): Promise<void> {
    try {
      this.loading = true
      await this.sendData(this.vehicleSeconds)
    } finally {
      this.loading = false
    }
  }

  add (v: number): void {
    const end = moment(this.startDateTime.toDate()).add(v - 1, 'seconds')
    this.end.date = end.format('YYYY-MM-DD')
    this.end.time = end.format('HH:mm:ss')
  }

  addSeconds (v: string): void {
    this.blanks = []
    const end = moment(`${this.start.date} ${v}`).add(this.interval - 1, 'seconds')
    this.end.date = end.format('YYYY-MM-DD')
    this.end.time = end.format('HH:mm:ss')
  }

  async adminTest () {
    const idToken = await firebase.auth().currentUser?.getIdToken()

    const res = await fetch('http://localhost:7771/mobile-dtg/us-central1/api/admin', {
      headers: {
        authorization: `Bearer ${idToken}`
      }
    })

    const text = await res.text()

    alert(text)
  }

  async dayCalculate (): Promise<void> {
    if (!confirm('real?')) return
    if (!AuthStore.firebaseUser) return
    try {
      this.loading = true
      const idToken = await AuthStore.firebaseUser.getIdToken()
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${idToken}`
        }
      }
      const res = await fetch(this.dataURL + 'admin/calculate-days', options)

      const text = await res.text()

      alert(text)
    } finally {
      this.loading = false
    }
  }
}
