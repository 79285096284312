var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-subheader',[_vm._v(" days "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.remove}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt.toLocaleString())+" ")]}},{key:"item.firstSecond.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.firstSecond.time).toLocaleString())+" ")]}},{key:"item.lastSecond.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.lastSecond.time).toLocaleString())+" ")]}},{key:"item.driveScore",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.stringify(item.driveScore))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":function($event){return _vm.dayCalculate(item.id)}}},[_vm._v(" calculate ")]),_c('v-btn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.removeOne(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }