
















































import { Component, Vue } from 'vue-property-decorator'
import tripCollection, { Trip } from '@/models/trip'
import firebase, { storage, db } from '@/plugins/firebase'

@Component<TripList>({
  created () {
    this.subscribe()
  },
  destroyed () {
    this.unsubscribe()
  }
})
export default class TripList extends Vue {
  headers = [
    { value: 'createdAt', text: 'createdAt' },
    { value: 'firstSecond.time', text: 'firstSecond.time' },
    { value: 'lastSecond.time', text: 'lastSecond.time' },
    { value: 'driveScore', text: 'score' },
    { value: 'firstAddress', text: 'firstAddress' },
    { value: 'lastAddress', text: 'lastAddress' },
    { value: 'id', text: 'id' }
  ]

  unsubscribe!: firebase.Unsubscribe
  snapshots: firebase.firestore.QueryDocumentSnapshot<Trip>[] = []
  loading = false

  async fetch (): Promise<void> {
    // const sn = await tripCollection.get()
  }

  get items (): Trip[] {
    return this.snapshots.map(doc => Object.assign(doc.data(), { id: doc.id }))
  }

  subscribe (): void {
    this.unsubscribe = tripCollection.orderBy('createdAt', 'desc').limit(10).onSnapshot(sn => {
      this.snapshots = sn.docs
    })
  }

  async view (item: Trip): Promise<void> {
    // const ref = storage.ref('trips').child(item.device.id).child(`${item.id}.json`)
    // const url = await ref.getDownloadURL()
    // const r = await axios.get(url)
    // console.log(r)
    // const a: HTMLAnchorElement = document.createElement('A') as HTMLAnchorElement
    // a.href = url
    // document.body.appendChild(a)
    // a.click()
    // document.body.removeChild(a)

    // const r = await fetch(url)
    // console.log(r)
    // const json = await r.json()
    // console.log(json)
  }

  remove (): void {
    if (!confirm('real?')) return
    const batch = db.batch()
    for (const s of this.snapshots) batch.delete(s.ref)
    batch.commit()
  }

  removeOne (id: string): void {
    if (!confirm('real?')) return
    tripCollection.doc(id).delete()
  }
}
