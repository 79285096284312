


































import { Component, Vue } from 'vue-property-decorator'
import deviceCollection, { Device } from '@/models/device'
import firebase, { db } from '@/plugins/firebase'

@Component<DeviceList>({
  created () {
    this.subscribe()
  },
  destroyed () {
    this.unsubscribe()
  }
})
export default class DeviceList extends Vue {
  headers = [
    { value: 'createdAt', text: 'createdAt' },
    { value: 'deviceId', text: 'deviceId' },
    { value: 'info.plateNo', text: 'info.plateNo' },
    { value: 'lastSecond.time', text: 'lastSecond.time' },
    { value: 'id', text: 'id' }
  ]

  unsubscribe!: firebase.Unsubscribe
  snapshots: firebase.firestore.QueryDocumentSnapshot<Device>[] = []

  async fetch (): Promise<void> {
    // const sn = await tripCollection.get()
  }

  get items (): Device[] {
    return this.snapshots.map(doc => doc.data())
  }

  subscribe (): void {
    this.unsubscribe = deviceCollection.orderBy('createdAt', 'desc').limit(10).onSnapshot(sn => {
      this.snapshots = sn.docs
    })
  }

  remove (): void {
    if (!confirm('real?')) return
    const batch = db.batch()
    for (const s of this.snapshots) batch.delete(s.ref)
    batch.commit()
  }
}
