

















































import { Component, Vue } from 'vue-property-decorator'
import dayCollection, { Day } from '@/models/day'
import firebase, { db } from '@/plugins/firebase'
import AuthStore from '@/store/AuthStore'

@Component<DayList>({
  created () {
    this.subscribe()
  },
  destroyed () {
    this.unsubscribe()
  }
})
export default class DayList extends Vue {
  headers = [
    { value: 'createdAt', text: 'createdAt' },
    { value: 'firstSecond.time', text: 'firstSecond.time' },
    { value: 'lastSecond.time', text: 'lastSecond.time' },
    { value: 'driveScore', text: 'score' },
    { value: 'firstAddress', text: 'firstAddress' },
    { value: 'lastAddress', text: 'lastAddress' },
    { value: 'tripCount', text: 'tripCount' },
    { value: 'id', text: 'id' }
  ]

  unsubscribe!: firebase.Unsubscribe
  snapshots: firebase.firestore.QueryDocumentSnapshot<Day>[] = []

  loading = false

  async fetch (): Promise<void> {
    // const sn = await tripCollection.get()
  }

  get items (): Day[] {
    return this.snapshots.map(doc => Object.assign(doc.data(), { id: doc.id }))
  }

  get dataURL (): string {
    if (process.env.VUE_APP_FIREBASE_DATA === 'emulator') return 'http://localhost:7771/mobile-dtg/us-central1/api/'
    return 'https://us-central1-mobile-dtg.cloudfunctions.net/api/'
  }

  subscribe (): void {
    this.unsubscribe = dayCollection.orderBy('createdAt', 'desc').limit(10).onSnapshot(sn => {
      this.snapshots = sn.docs
    })
  }

  remove (): void {
    if (!confirm('real?')) return
    const batch = db.batch()
    for (const s of this.snapshots) batch.delete(s.ref)
    batch.commit()
  }

  removeOne (id: string): void {
    if (!confirm('real?')) return
    dayCollection.doc(id).delete()
  }

  async dayCalculate (id: string): Promise<void> {
    if (!AuthStore.firebaseUser) return
    const r = confirm('진짜 할꺼?')
    if (!r) return
    try {
      this.loading = true
      const idToken = await AuthStore.firebaseUser.getIdToken()
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${idToken}`
        }
      }
      const res = await fetch(this.dataURL + `admin/calculate-day/${id}`, options)

      const text = await res.text()

      alert(text)
    } finally {
      this.loading = false
    }
  }
}
